import Cookies from 'js-cookie'
import { WILLS_PRICING_EXPERIMENT } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useWtPricingExperiment = () => {
  const { isEnabled, variation, isLoading } = useDecisionForFlag({
    name: WILLS_PRICING_EXPERIMENT.name,
    fallbackVariation: WILLS_PRICING_EXPERIMENT.variations.off,
  })

  if (
    isEnabled &&
    variation === WILLS_PRICING_EXPERIMENT.variations['higher_price_point']
  ) {
    Cookies.set(WILLS_PRICING_EXPERIMENT.cookieName as string, variation, {
      expires: 365,
    })
  } else if (
    isEnabled &&
    variation === WILLS_PRICING_EXPERIMENT.variations['lower_price_point']
  ) {
    Cookies.set(WILLS_PRICING_EXPERIMENT.cookieName as string, variation, {
      expires: 365,
    })
  } else {
    Cookies.remove(WILLS_PRICING_EXPERIMENT.cookieName)
  }

  return { variation, isLoading, isEnabled }
}
